.ai-screen{
    background: #EDEAFF;
    transition: all 250ms;
}

.ai-screen:hover{
    background: linear-gradient(136deg, #7145FD 9.38%, #F7AFF1 99.63%);
}

.ai-screen.disabled{
    cursor: default;
    pointer-events: none;
}
.ai-screen.disabled .button{
    background: #F7F6FF;
    color: #E0DDED;
}
.button{
    transition: all 250ms;
}
.ai-screen:hover .button{
    background:#F5F2FF;
    border-radius: 43px;
    color: #7145FD;
}

.ai-screen:hover .paragraph{
    color: white;
}