@tailwind base;
@tailwind components;
@tailwind utilities;

/* START: react-data-table-component OVERRIDES  */
.custom-table .rdt_Table{
    padding: 8px;
    border-radius: 20px;
    @apply bg-gray-moon;
}

.custom-table .rdt_Table .rdt_TableHeadRow {
    border-radius: 20px;
    border: 0.5px solid;
    min-height: 38px;
    @apply border-star-light2 bg-star-light4 text-bodyThreeBold text-gray-6;
}

.custom-table .rdt_Table .rdt_TableBody .rdt_TableRow{
    border-bottom:none;
    @apply bg-gray-moon;
}
.custom-table .rdt_Pagination{
    border-top: none;
    background: transparent;
}
/* END: react-data-table-component OVERRIDES  */

/* START: Tool Tip */
.tooltip-wrapper {
    cursor: pointer;
    @apply relative;
}
.tooltip-wrapper:hover .tooltip{
    display: block;
}
/* END: Tool Tip */


/* START: Validator Button */

.validator-btn{
    @apply flex px-2 py-1 rounded-lg bg-star-light3 gap-2 cursor-pointer text-bodyThree text-gray-6 whitespace-nowrap;
}

.validator-btn svg{
    @apply text-venus-default;
}

.validator-btn:hover{
    @apply bg-venus-default text-white;
}

.validator-btn:hover svg{
    @apply text-white;
}

.validator-btn.checked svg{
    color: #21A700;
}
.validator-btn.checked:hover{
    background: #21A700;
}
.validator-btn.checked:hover svg{
    @apply  text-white;
}

/* END: Validator Button */

.font-inter{
    font-family: Inter;
}

.paragraph-spacing p{
    margin: 14px 0;
}

#copy-container p{
    margin: 14px 0;
}
.public-DraftEditorPlaceholder-inner { position: absolute;   pointer-events: none;   }

/* #copy-container u{
    background-color: red;
} */


.toast-style .Toastify__toast{
    border-radius: 8px 0px 8px 8px;
    box-shadow: 0px 14px 32px 0px rgba(39, 39, 47, 0.06);
}

.toast-style .Toastify__toast-icon svg{
    fill: #7145FD;
}

.toast-style .Toastify__toast-body{
    color:transparent ;
    background: linear-gradient(79deg, #5F45FD 16.67%, #FDA2F5 67.13%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 14px;
    font-weight: 700;
    font-family: Onest;
}
.toast-style .Toastify__toast-body .text-subText{
    color:#5E5A73 ;
    background-clip: unset;
    background: transparent;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    font-family: Onest;
}

button:disabled.loader::after {
    content: "";
    width: 16px;
    height: 16px;
    border: 2px solid currentColor;
    border-top-color: transparent; /* Creates a spinner effect */
    border-radius: 50%;
    display: inline-block;
    animation: spin 0.8s linear infinite;
  }
  
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
